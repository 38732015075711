import React from 'react'
import { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
        const form = useRef()
        const sendEmail = (e) => {
                e.preventDefault();

                emailjs.sendForm('service_bz4d2ig', 'template_l2e4sid', form.current, 'kniKD1uKbrrY9HRBC')
                        .then((result) => {
                                console.log(result.text);
                        }, (error) => {
                                console.log(error.text);
                        });
        };
        return (
                <div className='join' id='join-us'>

                        <div className='join-left'>
                                <hr />
                                <div className='join-text'>
                                        <span className='stroke-text'>READY TO</span>
                                        <span>LEVEL UP</span>
                                </div>
                                <div className='join-text'>
                                        <span>YOUR BODY</span>
                                        <span className='stroke-text'>WITH US?</span>
                                </div>


                        </div>

                        <div className='join-right'>
                                <form ref={form} className='email-container' onSubmit={sendEmail}>
                                        <input type='email' name='user_email' placeholder='Enter your email' />
                                        <button className='btn btn-j'>Join Now</button>
                                </form>

                        </div>



                </div>
        )
}

export default Join
