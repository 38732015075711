import React from 'react'
import './Footer.css'
import linkedin from '../../assets/linkedin.png'
import github from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import Logo from '../../assets/logo.png'

const Footer = () => {
        return (
                <div className='footer'>



                        <hr />
                        <div className='socialmedia'>
                                <img src={github} alt="" />
                                <img src={linkedin} alt="" />
                                <img src={instagram} alt="" />
                        </div>

                        <div className='logo'>
                                <img src={Logo} alt='' />
                        </div>
                </div>
        )
}

export default Footer
