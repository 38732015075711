import React from 'react'
import './Plans.css';
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
  return (
          <div className='plans-container'>
                  c<div className='blur plans-blur-1'></div>
                  c<div className='blur plans-blur-2'></div>
                  <div className='programs-header' style={{   gap:'2rem'  }}>
                          <span className='stroke-text'>READY TO START </span>
                          <span>YOUR JOURNEY </span>
                          <span className='stroke-text'>NOW WITH US</span>
                  </div>
                  <div className='plans'>
                          {plansData.map((plan, i) => (
                                  <div className='plan' key={i}>
                                          {plan.icon} 
                                          <span>{ plan.name}</span>
                                          <span>Rs {plan.price} /- Only</span>
                                          
                                          <div className='features'>
                                                  {plan.features.map((feature, i) => (
                                                          <div className='feature'>
                                                                  <img src={whiteTick} alt="" />
                                                                  <span key={i}>{ feature}</span>
                                                          </div>
                                                  ))}
                                                  <div>
                                                          <span className='benfits'>See more benfits &rarr; </span>

                                                  </div>
                                                  <button className='btn'>Join Now</button>
                                          </div>
                                  </div>
                          ))}
                 </div>
         </div>
  )
}

export default Plans
